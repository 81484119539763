import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import './style.css';
export const Div = ({ children, ...restProps }) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx("div", { ...restProps, className: 'div ' + restProps.className, children: children }));
};
