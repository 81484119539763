import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import './style.css';
export const View = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx("div", { id: props.id, style: { width: props.width }, className: 'view', children: props.children }));
};
