import { Address } from 'everscale-inpage-provider';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import { gameAbi as abi } from './abi';
// const portals: Portal[] = [
//     {
//         id: 1,
//         type: 'blue',
//         portals: [
//             { number: 6, position: [] },
//             { number: 45, position: [] }
//         ]
//     },
//     {
//         id: 2,
//         type: 'blue',
//         portals: [
//             { number: 12, position: [] },
//             { number: 43, position: [] }
//         ]
//     },
//     {
//         id: 3,
//         type: 'red',
//         portals: [
//             { number: 7, position: [] },
//             { number: 29, position: [] }
//         ]
//     },
//     {
//         id: 4,
//         type: 'red',
//         portals: [
//             { number: 35, position: [] },
//             { number: 81, position: [] }
//         ]
//     }
// ]
class Game {
    constructor(params) {
        this._address = params.address;
        this._addressUser = params.addressUser;
        this._wallet = params.wallet;
        this._network = params.network;
        console.log('_wallet', this._wallet);
    }
    sunc(wallet) {
        this._wallet = wallet;
        console.log('_wallet', this._wallet);
        return true;
    }
    static generatedCells(size) {
        const fullArr = []; // y x
        const Max = size * size;
        // console.log('size', size)
        for (let i = 0; i < size; i++) {
            let arrX = [];
            for (let i2 = 0; i2 < size; i2++) {
                const num = i2 + (i * size);
                arrX.push(num + 1);
            }
            if (i % 2) {
                arrX = arrX.reverse();
            }
            fullArr.push(arrX);
        }
        return fullArr;
    }
    static getNumberFromXY(x, y, size) {
        const cells = this.generatedCells(size);
        try {
            // console.log('y, x', y, x)
            // console.log('cells', cells)
            // console.log('cells', cells[y][x])
            return cells[y - 1][x - 1];
        }
        catch (err) {
            console.error('getNumberFromXY', err);
            return 0;
        }
    }
    static beamsToPortal(beam, size) {
        const portal = [];
        for (let i = 0; i < beam.length; i++) {
            portal.push({
                id: i,
                type: Number(beam[i].type_) === 1 ? 'blue' : 'red',
                portals: [
                    {
                        number: this.getNumberFromXY(Number(beam[i].from.x), Number(beam[i].from.y), size),
                        position: [Number(beam[i].from.x), Number(beam[i].from.y)]
                    },
                    {
                        number: this.getNumberFromXY(Number(beam[i].to.x), Number(beam[i].to.y), size),
                        position: [Number(beam[i].to.x), Number(beam[i].to.y)]
                    }
                ]
            });
        }
        return portal;
    }
    static genMovePlayer(from, to) {
        const sizePixel = 46;
        const aX = from.x * sizePixel - (sizePixel / 2);
        const aY = from.y * sizePixel - (sizePixel / 2);
        const bX = to.x * sizePixel - (sizePixel / 2);
        const bY = to.y * sizePixel - (sizePixel / 2);
        const abY = aY - bY;
        const abX = aX - bX;
        const an = Math.atan2(abY, abX);
        const rotate = -((an * 180) / Math.PI);
        const distance = Math.sqrt((aX - bX) ** 2 + (aY - bY) ** 2);
        return {
            rotate,
            distance
        };
    }
    static genArrBoard(size, _portals) {
        const fullArr = [];
        const Max = size * size;
        const sizePixel = 44;
        const fixPixel = 1;
        const localPortals = this.beamsToPortal(_portals, size);
        const portals = this.beamsToPortal(_portals, size);
        for (let i = 0; i < size; i++) {
            let arrX = [];
            for (let i2 = 0; i2 < size; i2++) {
                const num = Max - i2 - (i * size); // 10
                const typePortal = portals.filter(p => p.portals[0].number === num || p.portals[1].number === num);
                let type = typePortal.length > 0 ? 'portal-' + typePortal[0].type : 'default';
                if (type !== 'default') {
                    const indexPortal = portals.findIndex(p => p.portals[0].number === num || p.portals[1].number === num);
                    const numOfPortal = typePortal[0].portals[0].number === num ? 0 : 1;
                    // console.log((i % 2 ? size - i2 : i2 + 1) - 1, (size - i) - 1, localPortals[indexPortal])
                    console.log((i % 2 ? size - i2 : i2 + 1) - 1, (size - i) - 1, localPortals[indexPortal]);
                    // localPortals[indexPortal].portals[numOfPortal].position = [ (i % 2 ? size - i2 : i2 + 1) - 1, (size - i) - 1 ] // x y
                }
                if (num === Max)
                    type = 'win';
                arrX.push({
                    number: num,
                    type: type,
                    position: [i, i2]
                });
            }
            if (i % 2) {
                arrX = arrX.reverse();
            }
            fullArr.push(arrX);
        }
        for (let i = 0; i < localPortals.length; i++) {
            if (localPortals[i].portals[0].position.length > 0) {
                const aX = localPortals[i].portals[0].position[0] * sizePixel - (sizePixel / 2) - fixPixel;
                const aY = localPortals[i].portals[0].position[1] * sizePixel - (sizePixel / 2) - fixPixel;
                const bX = localPortals[i].portals[1].position[0] * sizePixel - (sizePixel / 2) + fixPixel;
                const bY = localPortals[i].portals[1].position[1] * sizePixel - (sizePixel / 2) + fixPixel;
                const abY = aY - bY;
                const abX = aX - bX;
                const an = Math.atan2(abY, abX);
                localPortals[i].rotate = -((an * 180) / Math.PI);
                // localPortals[i].distance = Math.sqrt(((bX - aX) ** 2) + ((bY - aY) ** 2))
                localPortals[i].distance = Math.sqrt((aX - bX) ** 2 + (aY - bY) ** 2);
                // fullArr.filter((f) => {
                //     const ff = f.filter(f2 => f2.number === localPortals[i].portals[0].number
                //     || f2.number === localPortals[i].portals[1].number)
                //     if (ff) {
                //         return
                //     }
                // })
            }
        }
        console.log('localPortals', localPortals);
        return [fullArr, localPortals];
    }
    async getGameDay() {
        if (!this._wallet || !this._wallet.provider) {
            console.log('Error wallet');
            return undefined;
        }
        const net = this._network === 'venom' ? 'venom' : 'everscale';
        const dateObj = new Date();
        const month = dateObj.getUTCMonth() + 1; // months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        const newdate = year + '-' + month + '-' + day;
        const hash = await axios.get('https://cpapi.mazekine.com/v2/featuredBoards?net=' + net
            + '&subnet=' + (this._network === 'venom' ? 'testnet' : 'mainnet') + '&date_le=' + newdate);
        return new Address(hash.data);
    }
    async getAllGames() {
        if (!this._wallet || !this._wallet.provider) {
            console.log('Error wallet');
            return undefined;
        }
        const codeHash = await axios.get('https://cpapi.mazekine.com/v2/contractHash');
        console.log('codeHash', codeHash);
        if (!codeHash.data)
            return undefined;
        const typedHash = codeHash.data;
        const hash = this._network === 'venom' ? typedHash.venom[0].contractHash : typedHash.everscale[0].contractHash;
        try {
            const addresses = await this._wallet.provider
                .getAccountsByCodeHash({ codeHash: hash });
            console.log('addresses.accounts', addresses.accounts);
            console.log('Provider connect', this._wallet.provider);
            return addresses.accounts;
        }
        catch (error) {
            console.error(error);
            return [];
        }
    }
    async getRounds(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider)
            return undefined;
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.getRounds({ answerId: 0, status: null });
            const data = await getData.call();
            console.log('rounds', data);
            return data;
        }
        catch (error) {
            console.log('getRounds', error);
            return undefined;
        }
    }
    async getInfoForGames(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider)
            return undefined;
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.getBoard({ answerId: 0 });
            const data = await getData.call();
            console.log('board', data);
            return data;
        }
        catch (error) {
            console.log('getInfoForGames', error);
            return undefined;
        }
    }
    async getSeed(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider)
            return undefined;
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.seed({});
            const data = await getData.call();
            console.log('getSeed', data);
            return data;
        }
        catch (error) {
            console.log('getSeed', error);
            return undefined;
        }
    }
    async getOwner(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider)
            return undefined;
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.owner({ answerId: 0 });
            const data = await getData.call();
            console.log('getOwner', data);
            return data;
        }
        catch (error) {
            console.log('getOwner', error);
            return undefined;
        }
    }
    async getPrizeFundPerRound(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider)
            return undefined;
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.prizeFundPerRound({});
            const data = await getData.call();
            console.log('getPrizeFundPerRound', data);
            return data;
        }
        catch (error) {
            console.log('getPrizeFundPerRound', error);
            return undefined;
        }
    }
    async createRound(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider || !this._wallet.account) {
            console.log('createRound not start', this._wallet);
            return undefined;
        }
        if (!this._wallet.account.address)
            return undefined;
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.createRound({});
            const data = await getData.send({
                from: this._wallet.account.address,
                amount: new BigNumber(0.2).shiftedBy(9).toFixed(0),
                bounce: true
            });
            // data.
            // const data = await getData.call()
            console.log('createRound', data);
            return true;
        }
        catch (error) {
            console.log('createRound', error);
            return undefined;
        }
    }
    async giveUp(address) {
        if (!this._wallet || !this._wallet.provider || !this._wallet.account || !this._wallet.account.address) {
            console.log('giveUp not start', this._wallet);
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.giveUpAllowed({});
            const data = await getData.send({
                from: this._wallet.account.address,
                amount: new BigNumber(0.2).shiftedBy(9).toFixed(0),
                bounce: true
            });
            // data.
            // const data = await getData.call()
            console.log('giveUp', data);
            return true;
        }
        catch (error) {
            console.log('giveUp', error);
            return undefined;
        }
    }
    async joinRound(address, roundId) {
        if (!this._wallet || !this._wallet.provider || !this._wallet.account || !this._wallet.provider || !this._wallet.account.address) {
            console.log('joinRound not start', this._wallet);
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.joinRound({ roundId });
            const data = await getData.send({
                from: this._wallet.account.address,
                amount: new BigNumber(0.2).shiftedBy(9).toFixed(0),
                bounce: true
            });
            // data.
            // const data = await getData.call()
            console.log('joinRound', data);
            return true;
        }
        catch (error) {
            console.log('joinRound', error);
            return undefined;
        }
    }
    async startRoll(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.account)
            return undefined;
        if (!this._wallet.provider)
            return undefined;
        if (!this._wallet.account.address) {
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.roll({});
            const data = await getData.send({
                from: this._wallet.account.address,
                amount: new BigNumber(0.3).shiftedBy(9).toFixed(0),
                bounce: true
            });
            console.log('startRoll', data);
            return true;
        }
        catch (error) {
            console.log('startRoll', error);
            return undefined;
        }
    }
    async claim(address, round) {
        if (!this._wallet || !this._wallet.provider || !this._wallet.account || !this._wallet.provider || !this._wallet.account.address) {
            console.log('claim not start', this._wallet);
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.claim({ roundId: round });
            const data = await getData.send({
                from: this._wallet.account.address,
                amount: new BigNumber(0.3).shiftedBy(9).toFixed(0),
                bounce: true
            });
            console.log('claim', data);
            return true;
        }
        catch (error) {
            console.log('claim', error);
            return undefined;
        }
    }
    async getPlayerCell(address) {
        if (!this._wallet || !this._wallet.provider) {
            console.log('error getPlayerCell');
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const data = await contractGame.fields.playerCell();
            console.log('getPlayerCell', data);
            return data;
        }
        catch (error) {
            console.log('getPlayerCell', error);
            return undefined;
        }
    }
    async getPlayersForRound(address, id) {
        if (!this._wallet || !this._wallet.provider) {
            console.log('error getPlayersForRound');
            return undefined;
        }
        console.log('start getPlayersForRound');
        const plR = await this.getRoundsPlayers(address);
        const plC = await this.getPlayerCell(address);
        if (!plR || !plC)
            return undefined;
        const players = [];
        try {
            const findRound = plR.filter(pl => pl[0] === id);
            let usersForRound = [];
            if (findRound.length !== 0)
                usersForRound = findRound[0][1];
            for (let i = 0; i < usersForRound.length; i++) {
                const numberPlayer = plC.filter(pl => pl[0].toString() === usersForRound[i].toString());
                if (numberPlayer.length === 0)
                    break;
                const player = {
                    address: usersForRound[i],
                    number: Number(numberPlayer[0][1])
                };
                players.push(player);
            }
            return players;
        }
        catch (error) {
            console.log('getPlayersForRound', error);
            return undefined;
        }
    }
    async getRoundsPlayers(address) {
        if (!this._wallet || !this._wallet.provider) {
            console.log('error getRoundsPlayers');
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const data = await contractGame.fields.roundPlayers();
            console.log('getRoundsPlayers', data);
            return data;
        }
        catch (error) {
            console.log('getRoundsPlayers', error);
            return undefined;
        }
    }
    async getJackpot(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider) {
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const data = await contractGame.fields.boardTreasury();
            console.log('getJackpot', data);
            return data;
        }
        catch (error) {
            console.log('getJackpot', error);
            return undefined;
        }
    }
    async getPlayerRound(address) {
        if (!this._wallet)
            return undefined;
        if (!this._wallet.provider) {
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const data = await contractGame.fields.playerRound();
            console.log('getPlayerRound', data);
            return data;
        }
        catch (error) {
            console.log('getPlayerRound', error);
            return undefined;
        }
    }
    async getRoundLatestMove(address, round) {
        if (!this._wallet || !this._wallet.provider) {
            console.log('error getRoundLatestMove');
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const getData = contractGame.methods.getRoundLatestMove({ answerId: 0, roundId: round });
            const data = await getData.call();
            console.log('getRoundLatestMove', data);
            return data;
        }
        catch (error) {
            console.log('getRoundLatestMove', error);
            return undefined;
        }
    }
    async getBalance(address) {
        if (!this._wallet || !this._wallet.provider) {
            console.log('getBalance error');
            return undefined;
        }
        try {
            const balance = await this._wallet.provider.getBalance(address);
            console.log('getBalance', balance);
            return balance;
        }
        catch (error) {
            console.log('getBalance', error);
            return undefined;
        }
    }
    async getAllInfoGames(addresses) {
        const allInfo = [];
        const allRounds = [];
        const allseed = [];
        const allowner = [];
        const balance = [];
        const jackpot = [];
        for (let i = 0; i < addresses.length; i++) {
            allInfo.push(this.getInfoForGames(addresses[i]));
            allRounds.push(this.getRounds(addresses[i]));
            allseed.push(this.getSeed(addresses[i]));
            allowner.push(this.getOwner(addresses[i]));
            balance.push(this.getBalance(addresses[i]));
            jackpot.push(this.getJackpot(addresses[i]));
        }
        const allPromise = [
            Promise.all(allInfo),
            Promise.all(allRounds),
            Promise.all(allseed),
            Promise.all(allowner),
            Promise.all(balance),
            Promise.all(jackpot)
        ];
        const allPromiseData = await Promise.all(allPromise);
        const infos = allPromiseData[0];
        const rounds = allPromiseData[1];
        const seeds = allPromiseData[2];
        const owners = allPromiseData[3];
        const balances = allPromiseData[4];
        const jackpots = allPromiseData[5];
        // const infos = await Promise.all(allInfo)
        // const rounds = await Promise.all(allRounds)
        // const seeds = await Promise.all(allseed)
        // const owners = await Promise.all(allowner)
        const data = [];
        for (let i = 0; i < infos.length; i++) {
            data.push({
                info: infos[i],
                rounds: rounds[i],
                address: addresses[i],
                seed: seeds[i]?.seed ?? '',
                owner: owners[i]?.value0.toString() ?? '',
                balance: balances[i] ?? '',
                jackpot: jackpots[i]?.jackpot ?? ''
            });
        }
        return data;
    }
    onEvents(address, cb) {
        if (!this._wallet || !this._wallet.provider) {
            console.log('onEvents error');
            return undefined;
        }
        const contractGame = new this._wallet.provider.Contract(abi, address);
        try {
            const subscriber = contractGame.events(new this._wallet.provider.Subscriber());
            subscriber.on((event) => {
                cb(event.event, event.data);
                console.log('Event: ', event);
            });
            console.log('onEvents', subscriber);
            return true;
        }
        catch (error) {
            console.log('onEvents', error);
            return undefined;
        }
    }
}
export { Game };
