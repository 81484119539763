var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { checkIsCurrentBrowser } from "../helpers/utils";
import AppStore from "../images/AppStore.svg";
import DownloadApk from "../images/DownloadApk.svg";
import GooglePlay from "../images/GooglePlay.svg";
import { VENOM_CONNECT_MODAL_ID } from "../VenomConnect";
import AbstractPopUp, { SECONDS } from "./AbstractPopUp";
import { CardManager } from "./CardManager";
import { QrCard } from "./InnerCard";
import { WrongNetworkPopup } from "./WrongNetworkPopup";
var DoneButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #11a97d;\n  width: 100%;\n  max-width: 320px;\n  height: 56px;\n  color: white;\n  border-radius: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 20px;\n"], ["\n  background: #11a97d;\n  width: 100%;\n  max-width: 320px;\n  height: 56px;\n  color: white;\n  border-radius: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 20px;\n"])));
var Slide;
(function (Slide) {
    Slide[Slide["walletsList"] = 0] = "walletsList";
    Slide[Slide["currentWallet"] = 1] = "currentWallet";
    Slide[Slide["innerCard"] = 2] = "innerCard";
    Slide[Slide["waitingInstallation"] = 3] = "waitingInstallation";
})(Slide || (Slide = {}));
var SProviders = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  /* align-items: center; */\n"], ["\n  width: 100%;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  /* align-items: center; */\n"])));
var INITIAL_STATE = {
    show: false,
    wrongNetwork: false,
    isFullProvider: false,
    popUpText: {
        title: "Waiting for an action in the extension window",
        // text: '',
    },
};
export var Modal = function (_a) {
    var error = _a.error, clearError = _a.clearError, networkName = _a.networkName, initThemeConfig = _a.themeConfig, options = _a.options, onClose = _a.onClose, changeWallet = _a.changeWallet, disconnect = _a.disconnect;
    window.updateVenomModal = function (state) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (state.show !== undefined) {
                setShow(state.show);
            }
            if (state.themeConfig !== undefined) {
                setThemeConfig(state.themeConfig);
            }
            if (state.isFullProvider !== undefined) {
                setIsFullProvider(state.isFullProvider);
            }
            setWrongNetwork(state.wrongNetwork);
            if (state.isExtensionWindowOpen !== undefined) {
                setIsExtensionWindowOpen(state.isExtensionWindowOpen);
                if (state.isExtensionWindowOpen) {
                    setExtensionPause(true);
                }
                else {
                    setTimeout(function () {
                        setExtensionPause(false);
                    }, SECONDS * 1000);
                }
                setPopUpText(state.popUpText || INITIAL_STATE.popUpText);
            }
            return [2 /*return*/];
        });
    }); };
    var getWalletWaysToConnect = function (_walletId) {
        var ret = [];
        options.forEach(function (o) {
            ret = __spreadArray(__spreadArray([], ret, true), o.walletWaysToConnect, true);
        });
        return ret;
        // return options.find(({ id }) => id === _walletId)?.walletWaysToConnect;
    };
    // const getInitialSlide = () =>
    //   options.length > 1 ? Slide.walletsList : Slide.currentWallet;
    var getInitialSlide = function () { return Slide.currentWallet; };
    // выбираем начальный кошелёк
    var getInitialWalletOption = function () {
        return getInitialSlide() === Slide.currentWallet ? options[0] : undefined;
    };
    // const getInitialWalletWaysToConnect = () =>
    //   getInitialWalletOption()?.walletWaysToConnect;
    // выбираем способы подключения
    var getInitialWalletWaysToConnect = function () { return getWalletWaysToConnect(undefined); };
    var getInitialWalletWayToConnect = function () {
        var _a = getInitialWalletOption() || {}, id = _a.id, _walletWaysToConnect = _a.walletWaysToConnect;
        return ((id !== undefined &&
            id === walletId &&
            _walletWaysToConnect &&
            !(_walletWaysToConnect.length > 1) &&
            _walletWaysToConnect[0]) ||
            undefined);
    };
    var _b = useState(getInitialSlide), slide = _b[0], setSlide = _b[1];
    // не актуален
    var _c = useState(), walletId = _c[0], setWalletId = _c[1];
    var _d = useState(), walletWaysToConnect = _d[0], setWalletWaysToConnect = _d[1];
    var _e = useState(), walletWayToConnect = _e[0], setWalletWayToConnect = _e[1];
    var _f = useState(initThemeConfig), themeConfig = _f[0], setThemeConfig = _f[1];
    var _g = useState(INITIAL_STATE.show), show = _g[0], setShow = _g[1];
    var _h = useState(INITIAL_STATE.wrongNetwork), wrongNetwork = _h[0], setWrongNetwork = _h[1];
    var _j = useState(INITIAL_STATE.isFullProvider), isFullProvider = _j[0], setIsFullProvider = _j[1];
    var _k = useState(), isExtensionWindowOpen = _k[0], setIsExtensionWindowOpen = _k[1];
    var _l = useState(INITIAL_STATE.popUpText), popUpText = _l[0], setPopUpText = _l[1];
    useEffect(function () {
        var _a;
        setSlide(getInitialSlide || Slide.walletsList);
        setWalletId((_a = getInitialWalletOption()) === null || _a === void 0 ? void 0 : _a.id);
        setWalletWaysToConnect(getInitialWalletWaysToConnect);
        setWalletWayToConnect(getInitialWalletWayToConnect);
    }, [show]);
    var onCurrentWalletSelectorClick = function (id) {
        setWalletId(id);
        setWalletWaysToConnect(getWalletWaysToConnect(id));
        setSlide(Slide.currentWallet);
    };
    var onCloseCrossClick = function () {
        if (!getInitialWalletOption())
            setWalletId(undefined);
        if (!getInitialWalletWayToConnect())
            setWalletWaysToConnect(undefined);
        clearError === null || clearError === void 0 ? void 0 : clearError();
        setSlide(getInitialSlide);
        onClose();
    };
    var goBack = function () {
        switch (slide) {
            case Slide.innerCard:
                setSlide(Slide.currentWallet);
                if (!getInitialWalletWayToConnect())
                    setWalletWaysToConnect(getWalletWaysToConnect(walletId));
                break;
            case Slide.currentWallet:
                if (!getInitialWalletOption()) {
                    setWalletId(undefined);
                    setSlide(Slide.walletsList);
                }
                break;
            case Slide.walletsList:
            default:
                break;
        }
    };
    var onWalletCardItemClick = function (id) {
        onCurrentWalletSelectorClick(id);
    };
    // выбран способ коннекта
    var onCurrentCardItemClick = function (name, id, cb) {
        var _walletWayToConnect = walletWaysToConnect === null || walletWaysToConnect === void 0 ? void 0 : walletWaysToConnect.find(function (_walletWayToConnect) {
            return _walletWayToConnect.name === name && _walletWayToConnect.id === id;
        });
        setWalletWayToConnect(_walletWayToConnect);
        if (slide === Slide.currentWallet &&
            (_walletWayToConnect === null || _walletWayToConnect === void 0 ? void 0 : _walletWayToConnect.type) === "mobile") {
            setSlide(Slide.innerCard);
        }
        else {
            cb();
            onCloseCrossClick();
        }
    };
    // первый шаг с кошельками
    // сейчас не используется
    var walletCardList = useMemo(function () {
        return {
            type: Slide.walletsList,
            element: (_jsx(SProviders, { children: options.map(function (_a, i) {
                    var id = _a.id /* wallet */;
                    return (_jsx(CardManager, { name: "wallet.name", logo: "wallet.logo", description: "wallet.description", themeObject: themeConfig.theme, themeName: themeConfig.name, onClick: function () { return onWalletCardItemClick(id); }, isFirst: !i }, id));
                }) })),
            title: (_jsxs(_Fragment, { children: ["Choose the wallet to", _jsx("br", {}), "connect:"] })),
        };
    }, [options, themeConfig.theme]);
    // это уже конкретные варианты 2го уровня
    var currentWalletCards = useMemo(function () {
        // const walletName = options.find(({id}) => id === walletId)?.wallet.name;
        return {
            type: Slide.currentWallet,
            element: (
            // список на главной
            _jsx(SProviders, { children: walletWaysToConnect === null || walletWaysToConnect === void 0 ? void 0 : walletWaysToConnect.map(function (_a, i) {
                    var _b, _c;
                    var id = _a.id, name = _a.name, logo = _a.logo, logoWhite = _a.logoWhite, onClick = _a.onClick, type = _a.type, x = _a.options;
                    return (_jsx(CardManager, { name: name, logo: logo, logoWhite: logoWhite, themeObject: themeConfig.theme, themeName: themeConfig.name, onClick: function () { return onCurrentCardItemClick(name, id, onClick); }, connectorType: type, options: x, 
                        // надо только у первого передать что он первый
                        isFirst: !i, isBadBrowser: !options.reduce(function (r, wallet) {
                            return r ||
                                !!wallet.walletWaysToConnect
                                    .filter(function (way) { return way.type === "extension"; })
                                    .reduce(function (rInner, way) {
                                    return rInner ||
                                        checkIsCurrentBrowser(way.options.isCurrentBrowser).isCurrentBrowser;
                                }, false);
                        }, false), allBrowsersNames: options
                            .map(function (wallet) {
                            return wallet.walletWaysToConnect.map(function (way) {
                                var _a;
                                return (_a = way.options.isCurrentBrowser) === null || _a === void 0 ? void 0 : _a.map(function (browser) { return browser === null || browser === void 0 ? void 0 : browser.browser; });
                            });
                        })
                            .flat(100), browsersNames: (_c = (_b = x.isCurrentBrowser) === null || _b === void 0 ? void 0 : _b.flat) === null || _c === void 0 ? void 0 : _c.call(_b, 100) }, id));
                }) })),
            title: _jsx(_Fragment, { children: "Choose the way to connect:" }),
            // title: <>Choose the way to connect {walletName}:</>,
        };
    }, [options, themeConfig.theme, walletId, walletWaysToConnect]);
    var mobileAppsPopUp = useMemo(function () {
        var _a;
        if (!walletWayToConnect)
            return {
                type: Slide.innerCard,
                element: _jsx(_Fragment, { children: "No way to connect" }),
                title: "Error",
            };
        // ссылки на магазины скачивания
        return {
            type: Slide.innerCard,
            element: (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: {
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "32px",
                            width: "286px",
                            justifyContent: "space-between",
                            marginRight: "auto",
                            marginLeft: "auto",
                            rowGap: "16px",
                        } }, { children: [_jsx("div", __assign({ style: {
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                } }, { children: _jsx("div", { children: walletWayToConnect.options.qr ? (_jsx(_Fragment, { children: _jsx(QrCard, { qr: walletWayToConnect.options.qr, themeConfig: themeConfig }) })) : null }) })), (_a = walletWayToConnect.options.devises) === null || _a === void 0 ? void 0 : _a.map(function (device) {
                                if (device.type === "ios") {
                                    return (_jsx("a", __assign({ href: device.deepLink, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("img", { src: AppStore, alt: "" }) })));
                                }
                                if (device.type === "android") {
                                    return (_jsx("a", __assign({ href: device.deepLink, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("img", { src: GooglePlay, alt: "" }) })));
                                }
                                if (device.type === "apk") {
                                    return (_jsx("a", __assign({ href: device.deepLink, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("img", { src: DownloadApk, alt: "" }) })));
                                }
                                return null;
                            })] })), _jsx("div", __assign({ style: {
                            margin: "24px 0px 2px",
                            cursor: "pointer",
                            color: "#11A97D",
                        }, onClick: goBack }, { children: "Back" }))] })),
            title: (_jsxs(_Fragment, { children: ["Please connect with", _jsx("br", {}), walletWayToConnect.name] })),
        };
    }, [options, themeConfig.theme, walletId, walletWayToConnect]);
    var innerCard = mobileAppsPopUp;
    var waitingInstallation = useMemo(function () {
        return {
            type: Slide.waitingInstallation,
            element: (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: { marginTop: 20 } }, { children: ["We are currently waiting for the installation and configuration of the", " ", (error === null || error === void 0 ? void 0 : error.includes("Venom"))
                                ? "Venom"
                                : (error === null || error === void 0 ? void 0 : error.includes("Ever"))
                                    ? "Ever"
                                    : "", " ", "Wallet extension"] })), _jsx(DoneButton, __assign({ onClick: function () { return window.location.reload(); } }, { children: "Done" }))] })),
            title: _jsx(_Fragment, { children: "Waiting for the installation " }),
        };
    }, [options, themeConfig.theme]);
    var cards = [walletCardList, currentWalletCards, innerCard];
    var getCard = function () {
        return cards.find(function (card) { return card.type === slide; });
    };
    var card = getCard();
    var _m = useState(false), networkPause = _m[0], setNetworkPause = _m[1];
    var _o = useState(false), extensionPause = _o[0], setExtensionPause = _o[1];
    useEffect(function () {
        var condition = !!wrongNetwork && !show && !!isFullProvider;
        if (condition) {
            setNetworkPause(true);
        }
        else {
            setTimeout(function () {
                setNetworkPause(false);
            }, SECONDS * 1000);
        }
    }, [wrongNetwork, show, isFullProvider]);
    // removed error handling window, only if not found
    useEffect(function () {
        if (!!error && !error.includes("wallet is not found")) {
            onCloseCrossClick();
        }
    }, [error]);
    return (_jsxs(_Fragment, { children: [_jsx("link", { href: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap", rel: "stylesheet" }), _jsx("style", { children: "\n          #".concat(VENOM_CONNECT_MODAL_ID, " a {\n            text-decoration: none !important;\n            color: inherit !important;\n          }\n        ") }), error ? (error.includes("wallet is not found") ? (_jsx(AbstractPopUp, __assign({ show: !!error, hide: !error, themeObject: themeConfig.theme, cardHeader: {
                    text: waitingInstallation.title,
                } }, { children: waitingInstallation === null || waitingInstallation === void 0 ? void 0 : waitingInstallation.element }))) : (_jsx(_Fragment, {})
            // <AbstractPopUp
            //   show={!!error}
            //   hide={!error}
            //   themeObject={themeConfig.theme}
            //   onClose={onCloseCrossClick}
            //   cardHeader={{
            //     text: "Error",
            //   }}
            // >
            //   <>
            //     <div style={{ marginTop: 20 }}>{error}</div>
            //     <DoneButton onClick={onCloseCrossClick}>Close</DoneButton>
            //   </>
            // </AbstractPopUp>
            )) : (_jsxs(_Fragment, { children: [_jsx(AbstractPopUp, __assign({ show: show, onClose: onCloseCrossClick, themeObject: themeConfig.theme, cardHeader: {
                            text: (card === null || card === void 0 ? void 0 : card.title) || "your wallet",
                            // fontSize: card?.type === Slide.currentWallet ? 20 : undefined,
                            textAlign: "left",
                        }, goBack: slide !== getInitialSlide() ? goBack : undefined }, { children: card === null || card === void 0 ? void 0 : card.element })), _jsx(AbstractPopUp, __assign({ show: (!!wrongNetwork && !show && !!isFullProvider) || networkPause, hide: !(!!wrongNetwork && !show && !!isFullProvider), themeObject: themeConfig.theme, cardHeader: {
                            text: "Active network is wrong",
                        } }, { children: _jsx(WrongNetworkPopup, { textColor: themeConfig.theme.common.text.color, changeWallet: changeWallet, disconnect: disconnect, networkName: networkName }) })), _jsx(AbstractPopUp, __assign({ show: !!isExtensionWindowOpen || extensionPause, hide: !isExtensionWindowOpen, themeObject: themeConfig.theme, cardHeader: {
                            text: popUpText.title,
                        } }, { children: _jsx(_Fragment, { children: popUpText.text }) }))] }))] }));
};
var templateObject_1, templateObject_2;
