import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import './style.css';
export const Header = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx("div", { className: 'header', children: _jsxs("div", { className: 'header-in', style: { width: props.width }, children: [_jsx("span", { className: 'header-before', children: props.before }), _jsx("span", { className: 'header-children', children: props.children }), _jsx("span", { className: 'header-after', children: props.after })] }) }));
};
