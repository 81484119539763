import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import './style.css';
export const Button = ({ type = 'default', size = 'l', children, stretched = false, load = false, onClick = () => null, disabled = false, icon, ...restProps }) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsxs("button", { ...restProps, className: `btn ${type}-btn ${size}-btn ${stretched ? 'stretched-btn' : ''} ${load ? ' load-btn' : ''} ${disabled ? 'btn-disabled' : ''}`, onClick: !load ? onClick : () => null, disabled: disabled, children: [load ? _jsx(Oval, { height: 20, width: 20, color: "#fff", wrapperStyle: {}, wrapperClass: "", visible: true, ariaLabel: 'oval-loading', secondaryColor: "#eee", strokeWidth: 4, strokeWidthSecondary: 4 }) : icon, children] }));
};
