import { everDefaultLinks } from "./everwallet";
import { getVenomAndroid, getVenomIos, getVenomQr, venomDefaultLinks, } from "./venomwallet";
export * from "./everwallet";
export * from "./venomwallet";
var defaultLinks = {
    venomwallet: venomDefaultLinks,
    everwallet: everDefaultLinks,
};
export var getValueByKey = function (id, key) {
    if (id === void 0) { id = "venomwallet"; }
    return function (links) {
        var _a;
        if ((links === null || links === void 0 ? void 0 : links[key]) !== null && !!id) {
            // @ts-ignore
            var userValue = links === null || links === void 0 ? void 0 : links[key];
            if (userValue &&
                typeof userValue === "object" &&
                !Array.isArray(userValue) &&
                typeof (userValue === null || userValue === void 0 ? void 0 : userValue.targetLink) === "string") {
                if (key === "ios") {
                    if (id === "venomwallet") {
                        return getVenomIos((userValue === null || userValue === void 0 ? void 0 : userValue.targetLink) || undefined);
                    }
                }
                if (key === "android") {
                    if (id === "venomwallet") {
                        return getVenomAndroid((userValue === null || userValue === void 0 ? void 0 : userValue.targetLink) || undefined);
                    }
                }
                if (key === "qr") {
                    if (id === "venomwallet") {
                        return getVenomQr((userValue === null || userValue === void 0 ? void 0 : userValue.targetLink) || undefined);
                    }
                }
                return "/";
            }
            if (userValue)
                return userValue;
            var defaultValue = (_a = defaultLinks === null || defaultLinks === void 0 ? void 0 : defaultLinks[id]) === null || _a === void 0 ? void 0 : _a[key];
            if (defaultValue === undefined) {
                if (key === "qr") {
                    if (id === "venomwallet")
                        return getVenomQr();
                    // if (id === "everwallet") return getEverQr();
                }
                if (key === "ios") {
                    if (id === "venomwallet")
                        return getVenomIos();
                    // if (id === "everwallet") return getEverIos();
                }
                if (key === "android") {
                    if (id === "venomwallet")
                        return getVenomAndroid();
                    // if (id === "everwallet") return getEverAndroid();
                }
            }
            return defaultValue || "";
        }
        return null;
    };
};
export var DEFAULT = {
    id: "wallet",
    // wallet: {
    //   name: "wallet",
    //   description: "default wallet",
    //   logo: DefaultWalletLogo,
    // },
    walletWaysToConnect: [],
};
// export const WALLETCONNECT: ProviderOptions = {
//   id: "walletconnect",
//   name: "WalletConnect",
//   logo: WalletConnectLogo,
//   type: "qr",
//   check: "isWalletConnect",
//   package: {
//     required: [["infuraId", "rpc"]],
//   },
// };
