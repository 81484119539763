import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Header } from '../components';
import logo from '../img/logo.svg';
import user from '../img/user.svg';
import logout from '../img/logout.svg';
import './header.css';
import { addStr, weiToEth } from '../logic/utils';
export const HeaderBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const history = useNavigate();
    const onConnectButtonClick = async () => {
        props.venomConnect?.connect();
    };
    const onDisconnectButtonClick = async () => {
        props.venomProvider?.disconnect();
    };
    function changeNetwork(e) {
        props.setTypeNetwork(e.target.value);
        history('/');
        setTimeout(() => {
            history('/boards');
        }, 100);
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    useEffect(() => {
        if (props.provider) {
            // console.log('account', props.provider.account)
        }
    }, [props.provider]);
    return (_jsx(Header, { width: props.isDesktop ? `${props.widthDesktop}px` : '100%', before: _jsx("img", { src: logo, className: "logo", onClick: () => history('/') }), after: _jsx("div", { className: "balance-full-block", children: props.venomConnect
                ? _jsxs(_Fragment, { children: [" ", props.address && props.balance ? _jsxs("div", { className: "balance-block", children: [_jsx("img", { src: user, className: "logo-24" }), _jsxs("div", { children: [_jsx("div", { style: { fontSize: '14px' }, children: addStr(props.address) }), _jsxs("div", { className: 'text-secondory', children: [weiToEth(props.balance.toString(), 9), " ", props.networkId === 42 ? 'EVER' : 'VENOM'] })] }), _jsx(Button, { type: "secondory", size: 'm', onClick: () => onDisconnectButtonClick(), children: _jsx("img", { src: logout, className: "logo-20" }) })] }) : _jsx("div", { className: "balance-block", children: _jsx(Button, { type: "default", size: 'l', onClick: () => onConnectButtonClick(), children: "Connect wallet" }) })] }) : null }), children: !props.isMobile
            ? _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }, children: [_jsx(Button, { type: "tentery", style: { margin: '0 16px 0 16px' }, onClick: () => history('/boards'), children: "Boards" }), _jsx(Button, { type: "tentery", style: { margin: '0 16px 0 16px' }, children: "How to play" })] }) : null }));
};
