import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import './style.css';
export const AppRoot = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsxs("div", { id: "app_root", className: 'app_root', children: [props.header, _jsx("div", { className: `app_root_in ${props.header ? 'header-fix' : ''}`, children: props.children }), props.footer, props.modal ? _jsx("div", { className: 'modal-root', children: props.modal }) : null] }));
};
