import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Panel, Div, Button } from '../../components';
import imgBoard from '../../img/Playable.svg';
export const Main = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const history = useNavigate();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx(Panel, { id: props.id, children: _jsxs(Div, { className: "panel-main", style: props.isDesktop ? {} : { flexDirection: 'column' }, children: [_jsxs("div", { style: { marginRight: '80px', marginBottom: '16px' }, children: [_jsx("h1", { className: 'raider-font', children: "Dive Into Portal Mania and Get to the Treasure First!" }), _jsx("p", { className: "text", children: "Play with your friends, or compete for prizes and jackpots in public championships!" }), _jsxs("div", { className: "block-buttons", style: props.isMobile ? { width: '100%' } : {}, children: [_jsx(Button, { type: "default", stretched: true, onClick: () => history('/boards'), children: "Win now!" }), _jsx(Button, { type: "secondory", stretched: true, children: "How to play" })] })] }), props.isDesktop ? _jsx("img", { src: imgBoard }) : null] }) }));
};
