import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import './style.css';
export const Panel = ({ id, children, ...restProps }) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx("div", { id: id, ...restProps, className: 'panel', children: children }));
};
