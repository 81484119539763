var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
var SBadge = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  opacity: ", ";\n\n  max-height: ", ";\n  width: 100%;\n  margin: 8px 0;\n  padding: 4px 6px;\n\n  text-align: left;\n\n  background: ", ";\n  border-radius: 4px;\n\n  font-size: 12px;\n  line-height: 16px;\n\n  color: ", ";\n"], ["\n  opacity: ", ";\n\n  max-height: ", ";\n  width: 100%;\n  margin: 8px 0;\n  padding: 4px 6px;\n\n  text-align: left;\n\n  background: ", ";\n  border-radius: 4px;\n\n  font-size: 12px;\n  line-height: 16px;\n\n  color: ", ";\n"])), function (_a) {
    var isVisualHide = _a.isVisualHide;
    return (isVisualHide ? 0 : 1);
}, function (_a) {
    var isVisualHide = _a.isVisualHide;
    return (isVisualHide ? "20px" : "none");
}, function (_a) {
    var color = _a.item.warning.background.color;
    return color;
}, function (_a) {
    var color = _a.item.warning.text.color;
    return color;
});
export var Browsers;
(function (Browsers) {
    Browsers["isChrome"] = "Google Chrome";
    Browsers["isFirefox"] = "Mozilla Firefox";
})(Browsers || (Browsers = {}));
export var filterNameArr = function (nameArr) {
    var _a;
    // @ts-ignore
    return (_a = nameArr === null || nameArr === void 0 ? void 0 : nameArr.map(function (n) { return Browsers[n] || null; })) === null || _a === void 0 ? void 0 : _a.filter(function (n) { return !!n; });
};
var BrowserBadge = function (_a) {
    var browserName = _a.browserName, themeObject = _a.themeObject, isVisualHide = _a.isVisualHide;
    return (_jsxs(SBadge, __assign({ item: themeObject.item, isVisualHide: !!isVisualHide }, { children: ["Your browser is not supported now.", _jsx("br", {}), "Please install ", browserName, "."] })));
};
var BrowserText = function (_a) {
    var browserName = _a.browserName;
    return (_jsxs(_Fragment, { children: ["You need to install ", browserName, " browser"] }));
};
var ProviderBadge = function (_a) {
    var providerName = _a.providerName, themeObject = _a.themeObject, isVisualHide = _a.isVisualHide;
    return (_jsxs(SBadge, __assign({ item: themeObject.item, isVisualHide: !!isVisualHide }, { children: ["Your wallet is not supported now.", _jsx("br", {}), "Please install ", providerName, "."] })));
};
var ProviderText = function (_a) {
    var providerName = _a.providerName;
    return (_jsxs(_Fragment, { children: ["You need to install ", providerName] }));
};
export var notSupported = {
    browser: {
        Badge: BrowserBadge,
        Text: BrowserText,
    },
    provider: {
        Badge: ProviderBadge,
        Text: ProviderText,
    },
};
var templateObject_1;
