import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import './style.css';
export const Icon = ({ src, size = 24, type = 'default', ...restProps }) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx("img", { src: src, ...restProps, className: `icon icon-${size} ${type}-icon` }));
};
