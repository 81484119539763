import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Link } from '../components';
import logo2 from '../img/logo2.svg';
import telegram from '../img/Telegram.svg';
import facebook from '../img/Facebook.svg';
import twitter from '../img/Twitter.svg';
import github from '../img/Github.svg';
import reddit from '../img/Reddit.svg';
import medium from '../img/Medium.svg';
import './footer.css';
export const FooterBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const history = useNavigate();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsx("div", { className: "footer", children: _jsxs("div", { className: "footer-in", style: { width: props.widthDesktop }, children: [_jsxs("div", { className: "navigate", style: props.isMobile ? { flexDirection: 'column' } : {}, children: [_jsx("div", { className: "footer-logo", children: _jsx("img", { src: logo2, onClick: () => history('/') }) }), _jsxs("div", { className: "footer-menu", style: props.isMobile ? { flexDirection: 'column' } : {}, children: [_jsxs("div", { className: "link-block", children: [_jsx("h3", { children: "Product" }), _jsx(Link, { onClick: () => history('/boards'), children: "Boards" }), _jsx(Link, { children: "How to play" }), _jsx(Link, { children: "Create board" })] }), _jsxs("div", { className: "link-block", children: [_jsx("h3", { children: "Support" }), _jsx(Link, { children: "How to play" }), _jsx(Link, { children: "FAQ" })] })] }), _jsx("div", { className: "buttons", children: _jsx(Button, { stretched: true, type: "outline", size: "m", children: "Source code on GitHub" }) })] }), _jsx("div", { className: "hr" }), _jsxs("div", { className: "terms", children: [_jsxs("div", { children: [_jsx("span", { children: "\u00A9 Mazekine, 2023" }), _jsx(Link, { children: "Terms of use" }), _jsx(Link, { children: "Privacy policy" }), _jsx(Link, { children: "Cookies" })] }), _jsxs("div", { children: [_jsx(Link, { children: _jsx(Icon, { src: telegram, size: 20 }) }), _jsx(Link, { children: _jsx(Icon, { src: facebook, size: 20 }) }), _jsx(Link, { children: _jsx(Icon, { src: twitter, size: 20 }) }), _jsx(Link, { children: _jsx(Icon, { src: github, size: 20 }) }), _jsx(Link, { children: _jsx(Icon, { src: reddit, size: 20 }) }), _jsx(Link, { children: _jsx(Icon, { src: medium, size: 20 }) })] })] })] }) }));
};
